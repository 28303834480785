var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('no-ssr',[_c('div',{staticClass:"newsletter-page page-landing-newsletter mb-5"},[_c('h2',{staticClass:"title text-center"},[_vm._v(_vm._s(_vm.$t('registerPage.newsletter-title')))]),_c('div',{staticClass:"c-newsletter-page__form"},[_c('div',[_c('div',{staticClass:"wrapp-main-image wrap-row-newsletter"},[_c('div',{staticClass:"col-12 col-md-6 background-full left-newsletterpage"},[_c('img',{attrs:{"src":_vm.newsletterImage}})]),_c('div',{staticClass:"right-newsletterpage"},[_c('h3',{staticClass:"subtitle-1 mt-2 d-flex justify-content-center"},[_vm._v(_vm._s(_vm.$t('registerPage.subtitle'))+" ")]),_c('p',{staticClass:"subtitle-2",domProps:{"innerHTML":_vm._s(_vm.$t('registerPage.newsletterDescription'))}}),_c('b-form',{staticClass:"mt-5 form-newsletter",attrs:{"data-vv-scope":"form-cta-newsletter"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',{staticClass:"newsletter-input-email"},[_c('b-row',{staticClass:"grid-two-column",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xs":"12"}},[_c('b-form-group',{attrs:{"id":"groupEmail"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"email","placeholder":_vm.labelPlaceholderEmail,"validations":[
                    {
                      condition: _vm.errors.has('form-cta-newsletter.email'),
                      text: _vm.errors.first('form-cta-newsletter.email')
                    }
                  ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xs":"12"}})],1)],1),_c('b-form-group',{staticClass:"checkbox-privacy",attrs:{"id":"privacyPolicy"}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],attrs:{"name":"privacy","label":_vm.labelPrivacy,"validations":[
                  {
                    condition: _vm.errors.has('form-cta-newsletter.privacy'),
                    text: _vm.errors.first('form-cta-newsletter.privacy')
                  }
                ]},on:{"label-clicked":function($event){return _vm.navigateToPrivacyPolicy()}},model:{value:(_vm.form.privacyPolicy),callback:function ($$v) {_vm.$set(_vm.form, "privacyPolicy", $$v)},expression:"form.privacyPolicy"}})],1),_c('div',{staticClass:"c-newsletter-page__form__subscribe"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('subscribe')))])])],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }