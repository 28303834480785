var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"success-intro",style:({ 'backgroundImage': ("url(" + _vm.successImageUrl)})},[_c('div',{staticClass:"basic-wrapper"},[_c('div',{staticClass:"success-intro__left"},[_c('h1',[_vm._v(_vm._s(_vm.$t('thank-you.recap.newsletter-title')))]),_c('b-form',{ref:"newsletterPageForm",attrs:{"data-vv-scope":"newsletterPageForm","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('p',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.$t('thank-you.recap.newsletter-subtitle')))]),_c('div',{staticClass:"form-inputs"},[_c('b-form-group',{attrs:{"id":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"secondary",attrs:{"name":"email","type":"email","placeholder":_vm.$t('thank-you.recap.newsletter.form.email'),"validations":[
                {
                  condition: _vm.errors.has('newsletterPageForm.email'),
                  text: _vm.errors.first('newsletterPageForm.email')
                }
              ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('div',{staticClass:"valid-feedback"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('newsletter_success'))+" ")])])],1),_c('button',{staticClass:"btn btn-none",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('subscribe')))])],1),_c('b-form-group',{staticClass:"checkbox-privacy",attrs:{"id":"privacyPolicy"}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],attrs:{"name":"privacy","label":_vm.labelLinkPrivacy,"validations":[
              {
                condition: _vm.errors.has('newsletterPageForm.privacy'),
                text: _vm.errors.first('newsletterPageForm.privacy')
              }
            ]},model:{value:(_vm.form.privacyPolicy),callback:function ($$v) {_vm.$set(_vm.form, "privacyPolicy", $$v)},expression:"form.privacyPolicy"}})],1)],1)],1),_c('div',{staticClass:"success-intro__right"},[_c('BrandLogo',{attrs:{"fill":"#fff"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }