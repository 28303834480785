<template>
  <no-ssr>
    <div class="newsletter-page page-landing-newsletter mb-5">
      <h2 class="title text-center">{{ $t('registerPage.newsletter-title') }}</h2>
        <div class="c-newsletter-page__form">
          <div>
            <!-- <p class="subtitle" :class="{'cl-gray': !isSubscribed }">
              <span v-html="$tc('newsletter.subscribedSubtitle{subscribed}', isSubscribed ? 1 : 2)" />
            </p> -->
            <div class="wrapp-main-image wrap-row-newsletter">
              <div class="col-12 col-md-6 background-full left-newsletterpage" ><img :src="newsletterImage" /></div>
              <div class="right-newsletterpage">
                <h3 class="subtitle-1 mt-2 d-flex justify-content-center">{{$t('registerPage.subtitle') }} </h3>
                <p class="subtitle-2" v-html="$t('registerPage.newsletterDescription')"></p>
                <b-form data-vv-scope="form-cta-newsletter"  @submit.prevent="onSubmit" class="mt-5 form-newsletter">
              <b-form-group class="newsletter-input-email">
                <!-- <b-row class="grid-two-column" no-gutters>
                  <b-col sm="12" md="6" lg="6" xs="12">
                    <b-form-group id="groupFirstname" >
                      <base-input
                          name="firstname"
                          v-model.trim="form.firstname"
                          v-validate="'required'"
                          type="text"
                          :readonly="readOnly"
                          :placeholder="$t('newsletterPage.firstName') + '*'"
                          :validations="[
                            {
                              condition: errors.has('form-cta-newsletter.firstname'),
                              text: errors.first('form-cta-newsletter.firstname')
                            }
                          ]"
                        >
                              </base-input>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="6" lg="6" xs="12">
                        <b-form-group id="groupLastname" >
                          <base-input
                            name="lastname"
                            v-validate="'required'"
                            v-model="form.lastname"
                            :placeholder="$t('newsletterPage.lastName') + '*'"
                            type="text"
                            :readonly="readOnly"
                            :validations="[
                              {
                                condition: errors.has('form-cta-newsletter.lastname'),
                                text: errors.first('form-cta-newsletter.lastname')
                              }
                            ]"
                          >
                          </base-input>
                        </b-form-group>
                      </b-col>
                          </b-row> -->
                <b-row class="grid-two-column" no-gutters>
                  <b-col sm="12" md="12" lg="12" xs="12">
                    <b-form-group id="groupEmail" >
                      <base-input
                    name="email"
                    v-model.trim="form.email"
                    v-validate="'required|email'"
                    type="email"
                    :placeholder="labelPlaceholderEmail"
                    :validations="[
                      {
                        condition: errors.has('form-cta-newsletter.email'),
                        text: errors.first('form-cta-newsletter.email')
                      }
                    ]"
                  >
                </base-input>

                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="6" lg="6" xs="12">
                        <!-- <b-form-group id="groupDoB" >
                          <base-input
                            name="birthday"
                            v-model="form.birthday"
                            :placeholder="$t('newsletterPage.birthday')"
                            type="text"
                            :readonly="readOnly"
                            :validations="[
                              {
                                condition: errors.has('form-cta-newsletter.birthday'),
                                text: errors.first('form-cta-newsletter.birthday')
                              }
                            ]"
                          >
                          </base-input>
                        </b-form-group> -->
                      </b-col>
                          </b-row>
                <!-- <b-row class="grid-two-column" no-gutters>
                  <b-col sm="12" md="6" lg="6" xs="12">
                    <b-form-group id="groupCity" >
                      <base-input
                          name="city"
                          v-model.trim="form.city"
                          v-validate="'required'"
                          type="text"
                          :placeholder="$t('newsletterPage.city') + '*'"
                          :validations="[
                            {
                              condition: errors.has('form-cta-newsletter.city'),
                              text: errors.first('form-cta-newsletter.city')
                            }
                          ]"
                        >
                              </base-input>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="6" lg="6" xs="12">
                        <b-form-group id="groupGender" >
                          <base-input
                            name="gender"
                            v-model="form.gender"
                            :placeholder="$t('newsletterPage.gender')"
                            type="text"
                            :readonly="readOnly"
                            :validations="[
                              {
                                condition: errors.has('gender'),
                                text: errors.first('gender')
                              }
                            ]"
                          >
                          </base-input>
                        </b-form-group>
                      </b-col>
                          </b-row> -->
                            </b-form-group>
              <b-form-group id="privacyPolicy" class="checkbox-privacy">
                <base-check-box
                  name="privacy"
                  v-validate="'required:true'"
                  v-model="form.privacyPolicy"
                  @label-clicked="navigateToPrivacyPolicy()"
                  :label="labelPrivacy"
                  :validations="[
                    {
                      condition: errors.has('form-cta-newsletter.privacy'),
                      text: errors.first('form-cta-newsletter.privacy')
                    }
                  ]"
                >
                </base-check-box>
              </b-form-group>
              <!-- <b-form-group id="groupCheckBoxNews2" class="newsletter-terms-2">
                <base-check-box
                  name="privacy2"
                  :label="this.$t('termsMarketing', { linkPrivacy: privacyUrl })"
                  :validations="[
                    {
                      condition: errors.has('privacy2'),
                      text: errors.first('privacy2')
                    }
                  ]"
                >
                </base-check-box>
              </b-form-group>
              <b-form-group id="groupCheckBoxNews3" class="newsletter-terms-3">
                <base-check-box
                  name="privacy3"
                  :label="this.$t('termsProfiling', { linkPrivacy: privacyUrl })"
                  :validations="[
                    {
                      condition: errors.has('privacy3'),
                      text: errors.first('privacy3')
                    }
                  ]"
                >
                </base-check-box>
              </b-form-group> -->
              <div class="c-newsletter-page__form__subscribe">
                <button type="submit" class="btn btn-primary">{{ $t('subscribe') }}</button>
              </div>
            </b-form>
              </div>
            </div>
          </div>
        </div>
    </div>
  </no-ssr>
</template>

<script>
import { EventBus } from '@/helpers'
import { navigateToPrivacyPolicy } from 'theme/helpers'
import { toast } from '@/modules/notifications'
import Logger from '@/services/Logger'
import BaseInput from 'theme/components/Form/BaseInput'
import BaseCheckBox from 'theme/components/Form/BaseCheckBox'
import { pathTranslate } from '@/helpers/routeHelpers'

export default {
  inject: ['$validator'],
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        email: '',
        firstname: '',
        lastname: '',
        birthday: '',
        city: '',
        gender: '',
        privacyPolicy: false
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.current
    },
    isSubscribed () {
      return false // this.currentUser && this.currentUser.is_subscribed
    },
    privacyUrl () {
      return this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.urlCode } }).href
    },
    newsletterImage () {
      // let settings = this.$store.getters['storeConfig/newsletterSettings']
      // if (settings && Object.keys(settings).length && settings.modal_media_url) {
      //   return settings.modal_media_url
      // } else {
      //   return require('theme/assets/image/img_modal_NL.jpg')
      // }
      return require('theme/assets/image/newsletterImage2.jpg')
    },
    labelPrivacy () {
      return this.$t('newsletterPage.privacyPolicy')
    },
    labelPlaceholderEmail () {
      return this.$t('newsletter.insert-email')
    }
  },
  methods: {
    setProfiling (email = this.currentUser.email) {
      return this.$store.dispatch('user/setProfiling', { email, profiling: this.currentUser.profiling })
    },
    subscribeCustomer ({ email = this.currentUser.email, $event }) {
      return this.$store.dispatch('user/subscribeCustomer', { email, nlType: '' })
        .then((res) => {
          let status = res.data
          if (status !== 'SUBSCRIBED' && status !== 'UPDATED') {
            throw new Error('error')
          }
          this.clearForm()
          toast.success(this.$t('newsletterPage.successMessage'))
          EventBus.$emit('track:newsletter', { position: 'newsletter page' })
          return status
        })
        .catch((err) => {
          Logger.error(err)
          return err
        })
        .finally(() => {
          this.$store.commit('ui/hideLoader')
        })
    },
    unsubscribeCustomer (email = this.currentUser.email) {
      return this.$store.dispatch('user/unsubscribeCustomer', { email })
        .then(res => {
          Logger.debug(res)
          toast.success(this.$t('Change successful!'))
          this.clearForm()
          return res
        })
        .catch(err => {
          Logger.error(err)
          this.serverValidation = this.$t('GenericError')
          return err
        })
        .finally(() => {
          this.$store.commit('ui/hideLoader')
        })
    },
    onSubmit ($event) {
      const self = this
      this.$validator.validateAll('form-cta-newsletter')
        .then(isValid => {
          if (isValid) {
            let promArr = []
            if (!self.isSubscribed) {
              let nlType = ''
              promArr.push(self.$store.dispatch('user/subscribeCustomer', { email: self.form.email, nlType }))
            }
            if (self.isSubscribed) {
              promArr.push(self.$store.dispatch('user/unsubscribeCustomer', { email: self.form.email }))
            }
            Promise.all(promArr)
              .then(user => {
                let resp = user.length && user.length === 1 ? user[0] : user
                let parent = $('.newsletter-input-email')
                let classPut = ''
                let message = ''
                parent.find('.invalid-feedback').remove()
                parent.find('.valid-feedback').remove()
                if (typeof resp === 'string') {
                  message = self.$t('newsletter_success')
                  classPut = 'valid-feedback'
                } else {
                  message = self.$t(resp.message)
                  classPut = 'invalid-feedback'
                }
                parent.append(`<div class="${classPut}">${message}</div>`)

                self.$store.commit('ui/hideLoader', false)
                toast.success(self.$t('Change successful!'))
                self.$validator.reset()
              })
              .catch(error => {
                Logger.error(error)
                self.$store.commit('ui/hideLoader', false)
                error && error.message && toast.error(self.$t(error.message))
              })
              .finally(() => {
                self.$store.commit('ui/hideLoader')
              })
          }
        })
    },
    navigateToPrivacyPolicy () {
      return navigateToPrivacyPolicy(false)
    },
    clearForm () {
      this.form.email = ''
      this.form.privacyPolicy = false
      this.$validator.reset()
    }
  },
  mounted () {
    if (process.client) {
      document.body.classList.add('is-newsletter-page')
    }
  },
  destroyed () {
    if (process.client) {
      document.body.classList.remove('is-newsletter-page')
    }
  },
  components: {
    BaseInput,
    BaseCheckBox
  }
}
</script>
