<template>
  <div class="success-intro" :style="{ 'backgroundImage': `url(${successImageUrl}`}">
    <div class="basic-wrapper">
      <div class="success-intro__left">
        <h1>{{ $t('thank-you.recap.newsletter-title') }}</h1>
        <b-form ref="newsletterPageForm" data-vv-scope="newsletterPageForm"  @submit.prevent="onSubmit" novalidate>
          <p class="form-title">{{ $t('thank-you.recap.newsletter-subtitle') }}</p>
          <div class="form-inputs">
            <b-form-group id="email">
              <base-input
                name="email"
                v-model.trim="form.email"
                v-validate="'required|email'"
                type="email"
                class="secondary"
                :placeholder="$t('thank-you.recap.newsletter.form.email')"
                :validations="[
                  {
                    condition: errors.has('newsletterPageForm.email'),
                    text: errors.first('newsletterPageForm.email')
                  }
                ]"
              >
              </base-input>
              <div class="valid-feedback"><div> {{$t('newsletter_success')}} </div></div>
            </b-form-group>
            <button type="submit" class="btn btn-none">{{ $t('subscribe') }}</button>
          </div>
          <b-form-group id="privacyPolicy" class="checkbox-privacy">
            <base-check-box
              name="privacy"
              v-validate="'required:true'"
              v-model="form.privacyPolicy"
              :label="labelLinkPrivacy"
              :validations="[
                {
                  condition: errors.has('newsletterPageForm.privacy'),
                  text: errors.first('newsletterPageForm.privacy')
                }
              ]"
            >
            </base-check-box>
          </b-form-group>
        </b-form>
      </div>
      <div class="success-intro__right">
        <BrandLogo fill="#fff" />
      </div>
    </div>
  </div>
</template>

<script>
import { pathTranslate } from '@/helpers/routeHelpers'
import BrandLogo from 'theme/components/BrandLogo/BrandLogo'
import NewsletterPage from 'theme/pages/NewsletterPage'

export default {
  name: 'Intro',
  mixins: [NewsletterPage],
  data () {
    return {
      form: {
        privacyPolicy: false
      },
      successImageUrl: require('theme/assets/image/img-thankyou-page.jpeg')
    }
  },
  computed: {
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      return this.$t('thank-you.recap.newsletter.form.privacyPolicy', { linkPrivacy })
    }
  },
  methods: {
    clearForm () {
      this.form.email = ''
      this.form.privacyPolicy = false
      this.$validator.reset()
    }
  },
  components: {
    BrandLogo
  }
}
</script>
